/**
 * The navbar container
 */
import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect, useDispatch } from "react-redux";
// import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import FlashAlert from "../FlashAlert";
import PropTypes from "prop-types";
import ogLogo from "../../assets/images/OG_logo_frame.svg";
import accountActions from "../../redux/professional_account";
import AccessControl from "../../components/AccessControl";
import { useLocationId } from "../../utils/hooks";
import { languages } from "../UserProfileForm/UserProfileForm-component";
import { updateProfessionalUser } from "../../redux/professional_users";
import { getClient, showClient } from "../../redux/clients";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom/cjs/react-router-dom";
import { getLocationAttributes } from "../../redux/locations";
const { REACT_APP_CLIENT_ID } = process.env;

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({
  alertMessage: state.notifications.message,
  alertColor: state.notifications.color,
  alertTimeout: state.notifications.timeout,
  currentLanguage: state.i18nState.lang,
  currentUser: state.professionalAccount.currentUser,
  signedin: state.professionalAccount.signedin,
});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateProfessionalUser,
      logout: accountActions.logout,
    },
    dispatch
  );

/**
 * The navigation bar container. Renders the top bar with links to navigte in the site
 */
const MainNavBar = (
  {
    alertMessage,
    alertColor,
    alertTimeout,
    currentLanguage,
    currentUser,
    signedin,
    logout,
    updateProfessionalUser,
  },
  context
) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  // TODO: this seems very hardcoded and relies on domain name.
  // Also a crutial data is loaded from this component
  const clientId = REACT_APP_CLIENT_ID;
  const client = useSelector((state) => getClient(state, clientId));
  const { pathname } = useLocation();
  // TODO: move location paths to routes file
  const params = matchPath(pathname, { path: "locations/:locationId" });
  const locationIds = useSelector((state) => state.locations.idsList);
  const locationId =
    useLocationId() || params?.locationId || (locationIds || [])[0];
  const currentLocation = useSelector((state) =>
    getLocationAttributes(state, locationId)
  );

  useEffect(() => {
    if (clientId) {
      dispatch(showClient({ clientId }));
    }
  }, [clientId, dispatch]);

  /** toggles the open state of the navbar */
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  /** handles the click on logout */
  const handleLogoutAction = (event) => {
    event.preventDefault();
    logout(currentUser.tokens);
  };

  const handleLanguageChange = (language) => {
    updateProfessionalUser({
      user: {
        language,
      },
      userId: currentUser.id,
      locationId,
    });
  };

  return (
    <Navbar expand="sm" fixed="top" className="brand-nav">
      <img className="brand-logo" src={ogLogo} alt="og logo" />
      <NavbarToggler aria-label="navigation_toggle" onClick={toggle} />
      <aside>
        {currentLocation && (
          <span>
            <i className="far fa-map-pin" />
            <span className="ms-2">{currentLocation.name}</span>
          </span>
        )}
      </aside>

      <Nav navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav className="text-dark">
            <span className="text-uppercase">{currentLanguage}</span>
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(languages).map((language) => (
              <DropdownItem
                key={language}
                onClick={() => handleLanguageChange(language)}
              >
                {language === currentLanguage && <i className="far fa-check" />}
                <span className="ms-4">{languages[language]}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown>
          <DropdownToggle nav className="text-light" disabled>
            <i className="far fa-envelope" />
          </DropdownToggle>
        </UncontrolledDropdown>
        <UncontrolledDropdown>
          <DropdownToggle nav className="text-dark">
            <i className="far fa-user-circle me-2" aria-hidden="true"></i>
            {signedin && (
              <span className="d-none d-lg-inline-block">
                {currentUser.nickname || currentUser.email}
              </span>
            )}
            {!signedin && (
              <span className="d-none d-lg-inline-block">Login / Signup</span>
            )}
          </DropdownToggle>
          <DropdownMenu>
            <AccessControl
              role={currentUser?.role}
              module="profile"
              right="index"
            >
              {signedin && (
                <DropdownItem href="/profile" className="text-reset">
                  <i className="far fa-user me-1" />
                  <span className="ms-4">{context.t("Profile")}</span>
                </DropdownItem>
              )}
            </AccessControl>
            <AccessControl
              role={currentUser?.role}
              module="userManagement"
              right="index"
            >
              {signedin && (
                <DropdownItem href="/users" className="text-reset">
                  <i className="far fa-users" />
                  <span className="ms-4">{context.t("Users")}</span>
                </DropdownItem>
              )}
            </AccessControl>
            <AccessControl
              role={currentUser?.role}
              module="locations"
              right="index"
            >
              {signedin && (
                <DropdownItem href="/locations" className="text-reset">
                  <i className="far fa-home" />
                  <span className="ms-4">{context.t("Locations")}</span>
                </DropdownItem>
              )}
            </AccessControl>
            {signedin && (
              <DropdownItem
                href="#"
                onClick={handleLogoutAction.bind(this)}
                className="text-reset"
              >
                <i className="far fa-sign-out" />
                <span className="ms-4">{context.t("Logout")}</span>
              </DropdownItem>
            )}
            {!signedin && (
              <DropdownItem href="/account/login" className="text-reset">
                <i className="far fa-user" />
                <span className="ms-4">{context.t("Login")}</span>
              </DropdownItem>
            )}
            {!signedin && (
              <DropdownItem href="/account/register" className="text-reset">
                <i className="far fa-user-plus" />
                <span className="ms-4">{context.t("Register")}</span>
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
        {alertMessage && (
          <FlashAlert color={alertColor || "success"} timeout={alertTimeout}>
            {alertMessage}
          </FlashAlert>
        )}
      </Nav>
    </Navbar>
  );
};

/**  define proptype for the 'translation' function  */
MainNavBar.contextTypes = {
  t: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavBar);
